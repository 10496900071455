<template>
  <div>
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <users-list-filters
      :role-filter.sync="roleFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>عرض</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>السجلات</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="بحث..."
              />
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">إضافة مستخدم</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="لم يتم العثور على سجلات مطابقة"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.name)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">@{{ data.item.email }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ $t(data.item.role) }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ $t(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'users-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">تفاصيل</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="shouldShowEditItem(data.item.id)"
             :to="{ name: 'users-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">تعديل</span>
            </b-dropdown-item>

            <b-dropdown-item
            v-if="shouldShowEditItem(data.item.id)"
            @click="confirmDelete(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">حذف</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">عرض {{ dataMeta.from }} إلى {{ dataMeta.to }} من {{ dataMeta.of }} سجلات</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import UsersListFilters from './UsersListFilters.vue';
import useUsersList from './useUsersList';
import userStoreModule from '../userStoreModule';
import UserListAddNew from './UserListAddNew.vue';

export default {
  components: {
    UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  mounted() {
  },
  methods: {
    shouldShowEditItem(dataItemId) {
        // Check if connected user is not equal to data item id
        return this.currentUser.id !== dataItemId;
      },
    deleteUser(user) {
      store.dispatch('app-user/deleteUser', { id: user.id })
        .then((_response) => {
          this.$emit('refetch-data');
          this.refetchData();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم حذف المستخدم بنجاح',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          let message = 'لم يتم حذف المستخدم. حدث خطأ أثناء الحذف';
          if (error.response) {
            message = error.response.data.message;
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              text: message,
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          });
        });
    },
    confirmDelete(user) {
      this.$bvModal
        .msgBoxConfirm(`يرجى تأكيد أنك تريد حذف حساب ${user.name}.`, {
          title: 'يرجى التأكيد',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'نعم',
          cancelTitle: 'لا',
          cancelVariant: 'outline-warning',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteUser(user);
          }
        });
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user';
    const currentUser = JSON.parse(localStorage.getItem('userData'));

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const isAddNewUserSidebarActive = ref(false);
    const roleOptions = ref([]);
    store.dispatch('app-user/fetchRoles')
      .then((response) => {
        let { roles } = response.data;
        roles = roles
        .filter((role) => role.name !== "company_admin")
        .map((role) => ({
          value: role.id,
          label: role.name_arabic,
          ...role,
        }));

        roleOptions.value = roles;
      })
      .catch((error) => {
        console.log(error);
      });

    const statusOptions = [
      { label: 'نشط', value: 'Active' },
      { label: 'غير نشط', value: 'Inactive' },
    ];

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      statusFilter,
    } = useUsersList();

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      currentUser,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
